.fullscreen .modal-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  top: 0;
  left: 0;
}

.fullscreen .modal-dialog .modal-content {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  top: 0;
  left: 0;
}